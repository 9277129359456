export default {
  error: 'An error occured: %{error}',
  required: 'This field is required',
  image_required: 'An image is required',
  image_too_large: 'This file is more than 10MB, please upload a smaller file',
  image_unsupported_format:
    'Thid file format is not supported, please upload an jpg file',
  more_than_zero: 'The number must be more than 0',
  integer: 'The number must be an integer',
  email: 'This must be a valid email',
};
