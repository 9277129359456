export default {
  title: 'Krøniken',
  firstParagraph: `MARKDOWN: *Krøniken* er Studentenes Kameraklubbs eget fotomagasin. Hvert år samler *Krøniken*-redaksjonen de beste bildene fra klubbens medlemmer for å publisere dem i et vakkert magasin.`,
  subHeader: 'Bli med i redaksjonen 25/26 – skap et fotomagasin med oss!',
  secondParagraph: `MARKDOWN:
  Har du en liten lidenskap for fotografi, visuell design eller journalistikk? Nå har du sjansen til å bli med i redaksjonen til Krøniken! Vi søker engasjerte folk som vil være med på å skape inspirerende innhold, løfte frem fotografer og fremme bildene til medlemmene våre!  \n
  Som medlem i komitéen blir du med å lage den kommende, 5. utgaven av Krøniken. Du får være med å velge ut bilder og årets tema, være med på workshop og utfoldt deg kreativt i design av fotomagasinet vårt. \n

  **Elsker du å skrive?** Vi trenger skribenter som kan dekke trender, intervjue fotografer og lage dyptgående artikler. \n
  **Har du øye for design?** Hjelp oss med layout, visuell identitet og en sterk estetisk profil. Vi kommer hovedsakelig til å bruke InDesign, Photoshop og Figma. \n
  **Er du markedsføringskyndig?** Bli med og spre magasinet til et bredt publikum! Vi trenger folk som er flink på instagram!
  
  Dette er en unik mulighet til å bygge erfaring, få publisert arbeid og være en del av et kreativt fellesskap. Vil du være med på reisen? Ta kontakt med oss i dag og bli en del av vårt team!
  
  **Søknadsfrist 1.april!**`,
  join: 'Søk her!',
  submitImages: 'Send inn bilder her!',
  oldEditions: 'Tidligere utgaver',
  thirdParagraph: `MARKDOWN: Ønsker du å bla gjennom eldre utgaver av *Krøniken*? Noen av dem er digitalt arkivert:`,
  thirdEdition: '3. utgave: Studentlivet nå, og for 85 år siden',
  secondEdition: '2. utgave',
  buy: `MARKDOWN: Ønsker du å skaffe deg en kopi av de tidligere utgavene? Besøk oss i kjelleren eller send oss en mail på kroniken@studenteneskameraklubb.no. `,
};
