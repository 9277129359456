import styled from 'styled-components';

import Message from './Message';
import EventsList from './EventsList';
import ImageCarousel from './ImageCarousel';
import GalleryList from './GalleryList';

export const Events = styled(EventsList)`
  width: 50%;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30em;
  margin-bottom: 3em;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SectionBody = styled.div`
  margin: 20px;
`;

export const SectionHeader = styled.h2`
  margin-top: 0;
  font-size: 2em;
`;
export const SectionText = styled.span``;

export const SendMessage = styled(Message)`
  width: 100%;
  margin: 0 auto;
  padding: 10px;
`;

export const JoinButton = styled.a`
  background-color: #ee2642;
  color: white;
  padding: 1rem 2rem;
  border-radius: 1000px;
  text-decoration: none;
  font-size: 1.5rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #c61e35;
  }
`;

export const Carousel = styled(ImageCarousel)`
  width: 80%;
  height: 20em;
  margin: 1em auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Galleries = styled(GalleryList)`
  width: 80%;
  margin: 1em auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const InfoBanner = styled.a`
  display: block;
  background-color: #ee2642;
  color: white;
  padding: 1.2rem;
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
  font-weight: 900;
`;
