export default {
  error: 'Det skjedde en feil: %{error}',
  required: 'Dette feltet må fylles ut',
  image_required: 'Last opp et bilde',
  image_too_large:
    'Dette bildet er større enn 10MB, vennligst velg et mindre bilde',
  image_unsupported_format:
    'Denne filen kan ikke lastes opp, vennligst velg en jpg fil',
  more_than_zero: 'Tallet må være høyere enn 0',
  integer: 'Tallet må være et heltall',
  email: 'Dette må være en gyldig epost',
};
