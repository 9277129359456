export default {
  title: 'Krøniken',
  firstParagraph: `MARKDOWN: *Krøniken* is Studentenes Kameraklubb's own photo magazine. Once a year, the *Krøniken* editorial team gathers the best photos from
  the club's members and publishes them in a beautiful magazine.`,
  subHeader: `Join the editorial team 2025/26 – create a photo magazine with us!`,
  secondParagraph: `MARKDOWN: Do you have a small passion for photography, visual design, or journalism? Now you have the chance to join the editorial team of Krøniken! We are looking for passionate individuals who want to help create inspiring content, elevate photographers, and promote the images of our members!

  As a member of the committee, you’ll help create the upcoming 5th edition of Krøniken. You will be involved in selecting images, choosing the theme for the year, attending workshops, and expressing your creativity in designing our photo magazine.

  **Do you love to write?** We need writers who can cover trends, interview photographers, and write in-depth articles.

  **Do you have an eye for design?** Help us with layout, visual identity, and a strong aesthetic profile. We will primarily use InDesign, Photoshop, and Figma.

  **Are you marketing-savvy?** Join us and help spread the magazine to a wide audience! We need people who are great at Instagram!

  This is a unique opportunity to gain experience, have your work published, and be part of a creative community. Want to join the journey? Contact us today and become part of our team!

  **Application deadline: April 1st!**`,

  join: 'Sign up here!',
  submitImages: 'Submit images here!',
  oldEditions: 'Previous Editions',
  thirdParagraph: `MARKDOWN: Would you like to browse through older editions of *Krøniken*? Some of them are digitally archived:`,
  secondEdition: '2nd Edition',
  buy: `MARKDOWN: Would you like to acquire a copy? Visit us in the basement or send us a mail at kroniken@studenteneskameraklubb.no.`,
};
