import React from 'react';
import { useText } from 'common/hooks';
import { connect, ConnectedProps } from '@cerebral/react';
import { sequences, state } from 'client/app.cerebral';

import {
  Events,
  JoinButton,
  Section,
  SectionHeader,
  SectionBody,
  SectionText,
  SendMessage,
  Wrapper,
  Carousel,
  Galleries,
  InfoBanner,
} from './elements';

const deps = {
  user: state.app.user,
  isActiveMember: state.app.isActiveMember,
};

function Landing({
  user,
  isActiveMember,
}: typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <Wrapper>
      {user && isActiveMember.isActive ? (
        <InfoBanner href="https://www.facebook.com/groups/skntnu/permalink/1547641675934560">
          {t('frontPage.banner')}
        </InfoBanner>
      ) : null}
      <Section>
        <SectionBody>
          <SectionHeader>{t('frontPage.firstSection.title')}</SectionHeader>
          <SectionText>{t('frontPage.firstSection.text')}</SectionText>
        </SectionBody>
        <JoinButton href={'/join'}>{t('frontPage.join')}</JoinButton>
      </Section>
      <Events />
      <Carousel />
      <Galleries />
      <SendMessage />
    </Wrapper>
  );
}

export default connect(deps, Landing);
